import { Role } from "../models/Role";
// import moment from "moment-timezone";

export const MapRolesToEnum = (roles: string[]): Role[] => {
    const roleEnumValues: Role[] = roles.map((role) => Role[role as keyof typeof Role]);
    return roleEnumValues;
};

// export const ConvertUTCToLocal = (utcDate: Date, isEventDate: boolean): string => {
//     return (isEventDate) ? moment.utc(utcDate).tz(moment.tz.guess()).format("MM/DD/YYYY") : moment.utc(utcDate).tz(moment.tz.guess()).format("MM/DD/YYYY HH:mm:ss");
// };

export const ConvertUTCToLocal = (utcDate: Date, isEventDate: boolean): string => {
    const newDate = new Date(utcDate);

    if (isEventDate) {
        return newDate.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' });
    } else {
        const formattedDate = newDate.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' });
        const formattedTime = newDate.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', second: '2-digit' });
        return `${formattedDate} ${formattedTime}`;
    }
};

export const getUrlsArray = (urls: []) => {
    const urlsArray: any = [];
    urls.forEach((url: any) => {
        urlsArray.push({
            value: url?.value,
            key: url?.key,
        });
    });
    return urlsArray;
}

export const capitalizeFirstInWord = (s: string) => {
    if (typeof s !== 'string') {
        return '';
    }
    const arr = s.split(' ');
    for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].toLowerCase().slice(1);
    }
    return arr.join(' ');
}

export const GetLocalTimeFormat = () => {
    const date = new Date();
    const formattedDate = `${date.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' })} ${date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', second: '2-digit' })}`;
    return formattedDate;
}

export const ConvertTimeToLocal = (date: any) => {
    const newDate = new Date(date);
    const formattedDate = `${newDate.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' })} ${newDate.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', second: '2-digit'})}`;
    return formattedDate;
}